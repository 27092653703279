
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import { inject } from '@/inversify';
import { Popover } from 'element-ui';
import type Day from '@/modules/common/types/day.type';
import Currency from '@/modules/common/components/currency.vue';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CALENDAR_DATA_SOURCE from '@/modules/cars/constants/calendar-data-source.constant';
import _ from 'lodash';

interface Item {
    price: number;
    company: string;
    logo: string;
}

@Component({
    filters: { MIPriceFilter },
    components: {
        Popover,
        Currency,
    },
})
export default class CarBrandsOfBrokerTooltip extends Vue {
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    @Prop({
        type: Boolean,
        default: () => true,
    })
    show!: boolean;

    @Prop({
        type: String,
        required: true,
    })
    company!: string;

    @Prop({
        type: Number,
        required: true,
    })
    day!: Day;

    @Prop({
        type: String,
        default: () => CALENDAR_DATA_SOURCE.GROUPED,
    })
    calendarDataSource!: CALENDAR_DATA_SOURCE;

    get broker() {
        return this.company && this.company.split(',')[0];
    }

    get items() {
        if (!this.broker) {
            return [];
        }

        const data: Item[] = [];
        const allCars = this.carsService.allCars(this.day, false, this.broker, this.calendarDataSource) as {
            [company: string]: CarsDocumentItemModel
        };

        if (!allCars) {
            return [];
        }

        Object.keys(allCars).forEach(company => {
            const [, brand] = company.split(',');
            data.push({
                company: brand,
                price: this.carsService.getCarPrice(allCars[company]),
                logo: this.getLogo(brand),
            });
        });

        return data.sort((a, b) => {
            const secondPrice = b.price ? b.price : -1;
            const firstPrice = a.price ? a.price : -1;
            return firstPrice - secondPrice;
        });
    }

    getLogo(provider: string) {
        const images = require.context('@/modules/common/assets/logos', false, /\.png$|\.jpg$|\.gif$|\.svg$/);
        try {
            return images(`./${provider}.gif`);
        } catch (error) {
            return '';
        }
    }

    get brokerDisplayName() {
        const { carProvidersDisplayNamesMap } = this.carsFiltersService;
        return _.get(carProvidersDisplayNamesMap, this.broker) || this.broker;
    }
}
