
import CarsPriceHistoryService, { CarsPriceHistoryServiceS } from '@/modules/cars/cars-price-history.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarBrandsOfBrokerTooltip from '@/modules/cars/components/car-brands-of-broker-tooltip.vue';
import CarsPriceHistoryDocumentItemModel from '@/modules/cars/models/cars-price-history-document-item.model';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import Currency from '@/modules/common/components/currency.vue';
import DayChanger from '@/modules/common/components/day-changer.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import GraphLegendIcon from '@/modules/common/components/ui-kit/graph-legend-icon.vue';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import { DEFAULT_GRAPH_COLORS as GRAPH_COLORS } from '@/modules/common/constants/default-graph-colors.constant';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import PercentFilter from '@/modules/common/filters/percent.filter';
import type Day from '@/modules/common/types/day.type';
import type Percent from '@/modules/common/types/percent.type';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { inject } from '@/inversify';
import _ from 'lodash';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars-filters.service';
import CALENDAR_DATA_SOURCE from '../constants/calendar-data-source.constant';
import { BRAND_AS_BROKER_ANY } from '../constants/car-filter-types.constant';
import CarsDocumentItemModel from '../models/cars-document-item.model';

export interface TableData {
    price: number | null,
    company: string | null,
    carBrand: string | null,
    isAvailable?: boolean,
    screenshot: string | null,
    isMyCar: boolean,
    isMedian: boolean,
    sippCode?: string | null,
    shopDate?: Date | null,
    rectangleUniqueness?: string | null,
    rank?: number | null,
    originCompany?: string | null,
}

@Component({
    components: {
        DayChanger,
        ModalWrapper,
        Currency,
        PopupEventsContainer,
        CompsetScale,
        LoaderWrapper,
        CarBrandsOfBrokerTooltip,
        GraphLegendIcon,
    },
    filters: { PercentFilter, MIPriceFilter },
})

export default class CarPriceTable extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsPriceHistoryServiceS) carsPriceHistoryService!: CarsPriceHistoryService;

    @Prop({
        type: Number,
        required: true,
    })
    day!: Day;

    @Prop({
        type: String,
        default: () => CALENDAR_DATA_SOURCE.GROUPED,
    })
    calendarDataSource!: CALENDAR_DATA_SOURCE;

    @Prop({
        type: Boolean,
        default: () => false,
    })
    isHoverPopup!: boolean;

    infoTooltip: boolean = false;

    async openScreenshotPopup(item: TableData) {
        await this.$router.push({
            name: `${this.$route.name}.screenshot-popup`,
            params: {
                url: item.screenshot!.toString(),
                date: item.shopDate!.toString(),
                rectangleUniqueness: item.rectangleUniqueness!.toString(),
                provider: item.company!.toString(),
            },
        });
    }

    get documents() {
        return !!this.carsService.storeState.document;
    }

    get compsetDataType() {
        if (this.carsService) {
            return {
                isNA: this.isNA,
                isSoldOut: this.isSoldOut,
                isNoData: false,
            };
        }
        return {};
    }

    get priceType() {
        return this.carsService.storeState.settings.priceType;
    }

    get occupancyTitle() {
        if (!this.occupancyLevel) {
            return 'N/A';
        }
        return `${this.countCars} out of ${this.totalCars} (${this.occupancyLevel}%)`;
    }

    get occupancyLevel() {
        return this.carsService.occupancy(this.day) || 0;
    }

    get countCars() {
        const { countCars } = this.carsService.fleetAvailability(this.day);
        return countCars;
    }

    get totalCars() {
        const { totalCars } = this.carsService.fleetAvailability(this.day);
        return totalCars;
    }

    getCarPrice(car: CarsDocumentItemModel | CarsPriceHistoryDocumentItemModel, company?: string) {
        return (car as CarsPriceHistoryDocumentItemModel).price ? (car as CarsPriceHistoryDocumentItemModel).price
            : this.carsService.getCarPrice(car as CarsDocumentItemModel, company);
    }

    get tableData(): TableData[] | [] {
        const tableData: TableData[] = [];
        const allCars = this.carsService.allCars(this.day, false, undefined, this.calendarDataSource);

        const { competitors, carProvidersDisplayNamesMap } = this.carsFiltersService;
        const { isBrokerToBroker, brokersCompetitors } = this.carsService;
        const { currentBrandAsBroker } = this.carsService.storeState.settings;

        const { currentCompany } = this.userService;

        if (currentCompany === null || allCars === null) {
            return tableData;
        }

        Object.entries(allCars).forEach(([company, car]: [string, CarsDocumentItemModel]) => {
            if (car) {
                tableData.push({
                    price: this.getCarPrice(car, company),
                    carBrand: car.brand,
                    screenshot: car.screenshot,
                    isAvailable: car.isAvailable,
                    sippCode: car.sippCode,
                    company: this.resolveCompanyName(company),
                    originCompany: company,
                    isMyCar: car.isMainCar,
                    isMedian: false,
                    shopDate: car.shopDate,
                    rectangleUniqueness: car.rectangleUniqueness,
                });
            } else {
                tableData.push({
                    price: null,
                    carBrand: null,
                    screenshot: null,
                    isAvailable: true,
                    company: this.resolveCompanyName(company),
                    originCompany: company,
                    isMyCar: false,
                    isMedian: false,
                    rectangleUniqueness: null,
                });
            }
        });

        if (this.isBrokerMode) {
            const compainies = Object.keys(allCars);
            const brokers = [currentCompany];
            if (this.isBrokerToBroker) {
                brokers.push(...(brokersCompetitors || []));
            }
            if (allCars && currentBrandAsBroker === BRAND_AS_BROKER_ANY) {
                brokers.forEach(broker => {
                    const brandsByBroker = compainies.filter(item => item.split(',')[0] === broker && item !== broker);
                    if (brandsByBroker.length === 0) {
                        const brokerName = _.get(carProvidersDisplayNamesMap, broker) || broker;
                        tableData.push({
                            price: null,
                            carBrand: null,
                            screenshot: null,
                            isAvailable: true,
                            company: `${brokerName} (All)`,
                            isMyCar: broker === currentCompany,
                            isMedian: false,
                        });
                    }
                });
            }

            if (allCars && currentBrandAsBroker !== BRAND_AS_BROKER_ANY) {
                const [, brand] = currentBrandAsBroker.split(',');
                brokers.forEach(broker => {
                    const fullNameCompany = `${broker},${brand}`;
                    if (allCars[fullNameCompany] === undefined) {
                        tableData.push({
                            price: null,
                            carBrand: null,
                            screenshot: null,
                            isAvailable: true,
                            company: this.resolveCompanyName(fullNameCompany),
                            originCompany: fullNameCompany,
                            isMyCar: broker === currentCompany,
                            isMedian: false,
                        });
                    }
                });
            }
        }

        if (this.isBrandMode) {
            if (allCars && allCars[currentCompany] === undefined) {
                tableData.push({
                    price: null,
                    carBrand: null,
                    screenshot: null,
                    isAvailable: true,
                    company: currentCompany,
                    originCompany: currentCompany,
                    isMyCar: true,
                    isMedian: false,
                });
            }
        }

        if (allCars && competitors && !isBrokerToBroker) {
            competitors.forEach(competitor => {
                if (allCars[competitor] === undefined) {
                    tableData.push({
                        price: null,
                        carBrand: null,
                        screenshot: null,
                        isAvailable: true,
                        company: competitor,
                        isMyCar: false,
                        isMedian: false,
                    });
                }
            });
        }

        const competitorPrice = this.carsService.competitorPrice(this.day, this.calendarDataSource);

        if (competitorPrice !== null) {
            tableData.push({
                price: competitorPrice,
                carBrand: this.$t(`cars.priceType.${this.priceType}`) as string,
                company: '',
                screenshot: null,
                isMyCar: false,
                isMedian: true,
            });
        }
        let rankCount = 1;
        return tableData
            .filter(car => car.isMedian || car.isMyCar || this.carsFiltersService.isProviderAvailableInCurrentPOS(car.company || ''))
            .sort((a, b) => {
                const secondPrice = b.price ? b.price : -1;
                const firstPrice = a.price ? a.price : -1;
                if (this.isAvgPrice) {
                    return firstPrice - secondPrice;
                }
                return secondPrice - firstPrice;
            }).map((item: TableData): TableData => {
                const car = item;
                if (car.price && !car.isMedian) {
                    car.rank = rankCount++;
                }
                return item;
            });
    }

    get isPriceHistory() {
        return this.calendarDataSource === CALENDAR_DATA_SOURCE.HISTORY;
    }

    get isNA() {
        return this.carsService.allCars(this.day, true, undefined, this.calendarDataSource) === false;
    }

    get isSoldOut() {
        return this.carsService.currentCar(this.day, this.calendarDataSource) === null;
    }

    get cardColorClass() {
        if (!this.percent) {
            return { red: true };
        }

        const assessment = this.carsService.getCardAssessment(this.percent);

        return {
            green: assessment === ASSESSMENTS_TYPES.GOOD,
            yellow: assessment === ASSESSMENTS_TYPES.NORMAL,
            red: assessment === ASSESSMENTS_TYPES.BAD || this.isCompetitorsSoldOut(this.day),
        };
    }

    getScanAgeInDays(item: TableData): number {
        return !item.shopDate
            ? 0
            : moment.utc().endOf('day').diff(item.shopDate, 'days');
    }

    isCompetitorsSoldOut(day: Day) {
        return this.carsService.isCompetitorsSoldOut(day, this.calendarDataSource);
    }

    get percent(): Percent | null {
        return this.carsService.competitorPercent(this.day, this.calendarDataSource);
    }

    get shopDate() {
        const date = this.carsService.calculateShopDate(this.day, this.calendarDataSource);

        if (!date) {
            return null;
        }

        const day = (`0${date.getUTCDate()}`).slice(-2);
        const month = (`0${date.getUTCMonth() + 1}`).slice(-2);
        const year = date.getUTCFullYear();
        const hours = (`0${date.getUTCHours()}`).slice(-2);
        const minutes = (`0${date.getUTCMinutes()}`).slice(-2);

        return `${day}/${month}/${year} ${hours}:${minutes} UTC`;
    }

    get isOutOfRange() {
        const cars = this.carsService.allCars(this.day, true, undefined, this.calendarDataSource);
        return cars === null;
    }

    get showFleetIndicator() {
        const { branches } = this.carsFiltersService;
        if (!branches) {
            return true;
        }
        const { chain } = this.carsService.storeState.settings;

        return !(chain && chain.chainId);
    }

    get isAvgPrice() {
        return this.carsService.storeState.settings.isAvgPrice;
    }

    async openPriceHistory() {
        this.$router.push({
            name: 'cars-rates.calendar.price-history-popup',
            params: { day: `${this.day}` },
        });
    }

    resolveCompanyName(company: string) {
        const { currentCompany } = this.userService;
        const { carProvidersDisplayNamesMap } = this.carsFiltersService;
        const [broker, brand] = company.split(',');

        if (this.isBrokerMode) {
            if (this.isBrokerToBroker || broker === currentCompany) {
                const brokerName = _.get(carProvidersDisplayNamesMap, broker) || broker;
                return `${brokerName} (${brand})`;
            }
        }

        return company;
    }

    get isBrokerToBroker() {
        const { isBrokerToBroker } = this.carsService;
        return isBrokerToBroker;
    }

    get isBrokerMode() {
        const { isBrokerMode } = this.carsService;
        return isBrokerMode;
    }

    get isBrandMode() {
        const { isBrandMode } = this.carsService;
        return isBrandMode;
    }

    get isBrokerToBrand() {
        const { isBrokerToBrand } = this.carsService;
        return isBrokerToBrand;
    }

    get isAvailability() {
        return this.carsFiltersService.isAvailability;
    }

    colorByCompany(companyName: string) {
        return this.carsService.carsGraphColor[companyName];
    }

    carsGraphColor(companyName: string) {
        const { currentCompany } = this.userService;
        const { isBrokerToBrand, isBrokerToBroker } = this.carsService;

        if (isBrokerToBrand || isBrokerToBroker) {
            const [broker] = companyName.split(',');
            return PROVIDER_COLORS[broker] || GRAPH_COLORS.pop() || '#123456';
        }

        return currentCompany && companyName === currentCompany ? PROVIDER_COLORS[currentCompany] : this.colorByCompany(companyName);
    }
}

