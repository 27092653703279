var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.carsService.isAvgPrice)?_c('div',{class:{
        'toggle-prices': true,
        'toggle-prices--disabled': _vm.isDisabled,
    }},[_c('span',{staticClass:"toggle-prices__label"},[_vm._v("Price: ")]),_c('ul',{staticClass:"toggle-prices__list"},_vm._l((_vm.options),function(option){return _c('li',{key:option.text,class:{
                'toggle-prices__item': true,
                'toggle-prices__item--active': option.value === _vm.priceShown,
            }},[_c('button',{attrs:{"disabled":option.disabled},domProps:{"innerHTML":_vm._s(option.text)},on:{"click":function($event){_vm.priceShown = option.value}}})])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }