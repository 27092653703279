
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Occupancy extends Vue {
    @Prop({
        required: true,
        type: Number,
        validator(value: any): boolean {
            return value >= 0 && value <= 100;
        },
    })
    private occupancy?: number;

    @Prop({
        required: false,
        type: String,
        default: '#AAAAAA',
    })
    private emptyColor?: string;

    @Prop({
        required: false,
        type: String,
        default: '#52A9FF',
    })
    private fillColor?: string;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    private vertical?: boolean;

    get style() {
        const deg = this.vertical ? '180deg' : '270deg';
        const gradient = `${deg} ,${this.emptyColor} ${100 - (this.occupancy || 0)}%, ${this.fillColor} ${(100 - (this.occupancy || 0))}%`;
        return {
            background: `linear-gradient(${gradient})`,
            border: `0.5px solid ${this.fillColor}`,
        };
    }
}
