
import { Component, Vue } from 'vue-property-decorator';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import moment from 'moment';
import { inject } from '@/inversify';

@Component
export default class CarFleetAvailabilityTooltip extends Vue {
    @inject(CarsServiceS) private carsService!: CarsService;

    get fileName() {
        return this.carsService.FleetFileName;
    }

    get updatedDate() {
        if (!this.carsService.FleetUpdatedDate) {
            return '';
        }
        return moment(this.carsService.FleetUpdatedDate).format('h:mm A D.M.YY');
    }
}
